import Mustang from "../Assests/images/mustang.jpg";
import heart from "../Assests/images/heart.png";
import { getUiAddress } from "../Common/Utils";
import { useState } from "react";

const NewsFeed = ({ cast, wave }: { cast: any; wave: any }) => {
  const [waveCount, setWaveCount] = useState<number>(cast.wave_count);
  const handleWave = async () => {
    setWaveCount(Number(cast.wave_count) + 1);
    await wave(cast.cast_id - 1);
  };
  return (
    <div>
      <div className=" border-b-2 border-[#2f3336] my-8">
        <div className=" flex px-10 ">
          <img
            src={Mustang}
            alt=""
            className=" rounded-full w-[3.5rem] h-[3.5rem]"
          />
          <div className=" text-[1.25rem] text-white font-[700] mx-6 my-3">
            {getUiAddress(cast.caster)}
          </div>
        </div>
        <div className=" flex">
          <div className=" 2xl:w-[28rem] xl:w-[28rem] lg:w-[24rem] bg-[#202327] rounded-[1rem] flex h-[8rem] my-8 mx-12">
            <div className=" text-white font-[200] text-[1.5rem] p-4">
              {cast.message}
            </div>
          </div>
          <div className=" flex flex-col py-12">
            <img
              src={heart}
              onClick={handleWave}
              alt="heart"
              className=" w-6 h-6 cursor-pointer"
            />
            <div>
              <p className=" px-2 py-2">{waveCount}</p>
            </div>
          </div>
        </div>
        <div className=" flex justify-between p-[2%_12%]"></div>
      </div>
    </div>
  );
};

export default NewsFeed;
