import axios from "axios";
import { payKey, paymasterBaseUrl } from "../Common/Constants";

const IsWhitelisted = async (address: string) => {
  try {
    const url = `${paymasterBaseUrl}/is-whitelisted`;

    const data = {
      address,
    };
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": payKey,
      },
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.log("e", e);
  }
};

export default IsWhitelisted;
