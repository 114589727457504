import Sidebar from "./Sidebar";
import Mustang from "../Assests/images/mustang.jpg";
import NewsFeed from "./NewsFeed";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import {
  CHAIN_NAMESPACES,
  WALLET_ADAPTERS,
  ADAPTER_EVENTS,
  CONNECTED_EVENT_DATA,
  SafeEventEmitterProvider,
} from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { useEffect, useState } from "react";
import { AptosAccount } from "aptos";
import { getUiAddress } from "../Common/Utils";
import { getAllCasts } from "../Services/GetAllCasts";
import execute from "../Services/Execute";
import IsWhitelisted from "../Services/IsWhitelisted";
import AddToWhitelist from "../Services/AddToWhitelist";
import initAccount from "../Services/InitAccount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clientId = process.env.REACT_APP_WEB3_AUTH;
const MainContent = () => {
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
  console.log("web3auth", web3auth);
  const [userAccount, setUserAccount] = useState<AptosAccount | null>(null);
  const [userFullAddress, setUserFullAddress] = useState<string | null>(null);
  const [allCasts, setAllCasts] = useState<any[]>([]);
  const [userUiAddress, setUserUiAddress] = useState<string | null>(null);
  const [currentCastInput, setCurrentCastInput] = useState<string>("");
  const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(
    null
  );

  useEffect(() => {
    const init = async () => {
      try {
        const web3auth = new Web3AuthNoModal({
          clientId,
          web3AuthNetwork: "testnet", // mainnet, aqua,  cyan or testnet
          chainConfig: {
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: "0x13881",
            rpcTarget: "https://rpc.ankr.com/polygon_mumbai", // This is the public RPC we have added, please pass on your own endpoint while creating an app
          },
        });
        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            network: "testnet",
            clientId: clientId,
          },
          loginSettings: {
            mfaLevel: "default",
          },
        });
        web3auth.configureAdapter(openloginAdapter);
        web3auth.on(ADAPTER_EVENTS.CONNECTED, async () => {
          console.log("connected");
        });
        setWeb3auth(web3auth);

        await web3auth.init();

        if (web3auth.provider) {
          setProvider(web3auth.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);
  useEffect(() => {
    async function allCasts() {
      let all: any[] = await getAllCasts();
      console.log("im running");
      setAllCasts(all.reverse());
    }
    allCasts();
  }, [currentCastInput]);
  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const web3authProvider = await web3auth.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        mfaLevel: "default",
        loginProvider: "google",
      }
    );
    const privateKey = (await web3auth.provider?.request({
      method: "private_key",
    })) as string;
    const parsePrivateKey = (key: string): Uint8Array => {
      const formattedKey = key.startsWith("0x") ? key.slice(2) : key;
      if (formattedKey !== null) {
        return new Uint8Array(
          formattedKey.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
        );
      }
      throw new Error("Invalid key");
    };

    let wallet = new AptosAccount(parsePrivateKey(privateKey));
    setUserAccount(wallet);
    let addr = wallet.address().hex();
    setUserFullAddress(addr);
    whitelistAddress(addr);
    setUserUiAddress(getUiAddress(addr));
    setProvider(web3authProvider);
  };

  const whitelistAddress = async (address: string) => {
    await initAccount(address);
    console.log(" IsWhitelisted ", await IsWhitelisted(address));
    if (!(await IsWhitelisted(address))) await AddToWhitelist(address);
    console.log("success");
  };

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3auth.logout();
    setProvider(null);
  };
  const loggedInView = (
    <>
      <div className="flex-container flex">
        <div className=" mx-4 my-2.5 cursor-pointer"> {userUiAddress}</div>
        <div className="">
          <button
            onClick={logout}
            className="w-[10.25rem] h-[3.25rem] outline-none flex justify-center items-center bg-[#808080] rounded-[1rem] text-white font-[500] "
          >
            Logout
          </button>
          <button
            onClick={logout}
            className="w-[10.25rem] h-[3.25rem] outline-none flex justify-center items-center rounded-[1rem] text-white font-[500] "
          ></button>
        </div>
      </div>
    </>
  );

  const unloggedInView = (
    <button
      onClick={login}
      className="w-[10.25rem] h-[3.25rem] outline-none flex justify-center items-center bg-[#1d9bf0] rounded-[1rem] text-white font-[500] "
    >
      Login
    </button>
  );

  const cast = async () => {
    const payload: any = {
      function:
        "0x6ac859d53df901f70b4cdd48690cad4aab52e5906f792ceac10c2efeffbf7321::broadcast::cast",
      type_arguments: [],
      arguments: [currentCastInput],
      type: "entry_function_payload",
    };
    let exe = await execute(payload, userAccount);
    setCurrentCastInput("");
    console.log("execuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuute", exe);
    toast.success(`Successfully Casted ${userFullAddress}!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const wave = async (castIndex: number) => {
    const payload: any = {
      function:
        "0x6ac859d53df901f70b4cdd48690cad4aab52e5906f792ceac10c2efeffbf7321::broadcast::wave",
      type_arguments: [],
      arguments: [castIndex],
      type: "entry_function_payload",
    };
    let exe = await execute(payload, userAccount);
    console.log("execuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuute", exe);
  };

  const handleCastInput = (e: any) => {
    // Update the state when the input value changes
    setCurrentCastInput(e.target.value);
  };
  return (
    <div>
      <div className=" flex ">
        <Sidebar />
        <div className=" text-white border-r-2 border-[#2f3336] w-3/4 overflow-auto 2xl:ml-[25rem] xl:ml-[25rem] lg:ml-[17rem] sm:ml0 md:ml-0 max-sm:ml-0 z-[1]">
          <div className="!fixed w-3/4 top-0 text-[white] h-[5rem] !z-[2] py-[1rem] xxl:px-[48px] xl:px-[48px] lg:px-[48px] md:px-[48px] sm:px-[20px] xd:px-[20px] !bg-[rgba(29,30,32,0.10)] backdrop-blur-[50px] flex justify-between  ">
            <span className=" text-[2.5rem] font-[1400] flex justify-center items-center">
              Broadcast
            </span>
            <div className="grid">
              {provider ? loggedInView : unloggedInView}
            </div>
          </div>
          <div className="border-b-2 border-[#2f3336] mt-24 h-[10rem]  ">
            <div className="flex mx-12">
              <img
                src={Mustang}
                alt="Mustang"
                className=" rounded-full w-[3.5rem] h-[3.5rem] mt-3"
              />
              <input
                className=" bg-black w-[30rem] h-[5rem] outline-none border-b-2 border-[#2f3336] mx-5"
                placeholder="What is hapening?!"
                onChange={handleCastInput}
              />
            </div>
            <div className=" my-4 flex justify-center items-center">
              <button
                onClick={cast}
                className="w-[5.25rem] h-[2.25rem] outline-none flex justify-center items-center bg-[#1d9bf0] rounded-[1rem] text-white font-[500] mx-10 "
              >
                Cast
              </button>
              <ToastContainer />
            </div>
          </div>
          <div>
            {allCasts.length > 0 ? (
              allCasts.map((item, index) => (
                <NewsFeed key={index} cast={item} wave={wave} />
              ))
            ) : (
              <p>No casts.</p>
            )}
          </div>
          {/* <NewsFeed /> */}
        </div>

        {/* <div className=" py-24 ">
          <div className=" 2xl:px-20 xl:px-20 lg:px-2 "> */}
        {/* <div className=" 2xl:w-[24rem] xl:w-[18rem] lg:w-[14rem] bg-[#202327] rounded-[1rem] flex ">
              <img src={search} alt="search" className=" pl-3 w-10 h-10 pt-4" />
              <input
                className=" bg-[#202327] 2xl:w-[22rem] xl:w-[20rem] lg:w-[12rem] rounded-[1rem] h-[3.5rem] text-white outline-none pl-4 flex"
                placeholder="Search"
              />
            </div> */}
        {/* <div className="2xl:w-[24rem] xl:w-[18rem] lg:w-[14rem] bg-[#202327] rounded-[1rem] flex h-[42rem] my-6 overflow-auto">
              <div className=" text-white font-[700] text-[1.75rem] p-4">
                What's Happening
              </div>
            </div> */}
        {/* </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainContent;
