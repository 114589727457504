import axios from "axios";
import { payKey, paymasterBaseUrl } from "../Common/Constants";

const AddToWhitelist = async (address: string) => {
  try {
    console.log("inside add to whitelist");
    const url = `${paymasterBaseUrl}/add-to-whitelist`;

    const data = {
      address,
    };
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": payKey,
      },
    });
    console.log("response", response.data);
  } catch (e) {
    console.log("e", e);
  }
};

export default AddToWhitelist;
