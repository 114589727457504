import React from "react";
import Logo from "../Assests/images/Logo.svg";
import home from "../Assests/images/home.png";
// import search from "../Assests/images/search.svg";
// import notification from "../Assests/images/notification.png";
// import messages from "../Assests/images/messages.png";
// import list from "../Assests/images/list.png";
// import communities from "../Assests/images/communities.svg";
// import profile from "../Assests/images/profile.png";
const Sidebar = () => {
  return (
    <div>
      <div className="w-full  h-screen fixed  !z-[1] transition-transform duration-300 ease-in items-start xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden ">
        <div className=" 2xl:w-[25rem] xl:w-[25rem] lg:w-[17rem] h-screen border-r-2 border-[#2f3336]">
          <div className=" flex justify-center items-center pt-8">
            <img src={Logo} alt="Logo" />
          </div>
          <ul className=" text-white 2xl:px-36 xl:px-36 lg:px-16 leading-9 font-[700]">
            <li className=" cursor-pointer pt-8 flex ">
              <img src={home} alt="Home" className=" w-[2rem] h-[2rem]" />
              <span className=" pl-3">Home</span>
            </li>
            {/* <li className=" cursor-pointer pt-8 flex ">
              <img src={search} alt="search" className=" w-[2rem] h-[2rem]" />
              <span className=" pl-3">Explore</span>
            </li>
            <li className=" cursor-pointer pt-8 flex ">
              <img
                src={notification}
                alt="notification"
                className=" w-[2rem] h-[2rem]"
              />
              <span className=" pl-3">Notification</span>
            </li>
            <li className=" cursor-pointer pt-8 flex ">
              <img
                src={messages}
                alt="messages"
                className=" w-[2rem] h-[2rem]"
              />
              <span className=" pl-3">Messages</span>
            </li>
            <li className=" cursor-pointer pt-8 flex ">
              <img src={list} alt="list" className=" w-[2rem] h-[2rem]" />
              <span className=" pl-3">Lists</span>
            </li>
            <li className=" cursor-pointer pt-8 flex ">
              <img
                src={communities}
                alt="communities"
                className=" w-[2rem] h-[2rem]"
              />
              <span className=" pl-3">Communities</span>
            </li>
            <li className=" cursor-pointer pt-8 flex ">
              <img src={profile} alt="Profile" className=" w-[2rem] h-[2rem]" />
              <span className=" pl-3">Profile</span>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
