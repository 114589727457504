import axios from "axios";
import { aptosClient, payKey, paymasterBaseUrl } from "../Common/Constants";
const initAccount = async (address: string) => {
  try {
    await aptosClient.getAccount(address);
  } catch (e: any) {
    console.log("catched error ", e);
    try {
      const url = `${paymasterBaseUrl}/init-account`;
      const data = {
        address,
      };
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "pay-key": payKey,
        },
      });
    } catch (e: any) {
      console.log("catched paymaster error ", e);
    }
  }
};
export default initAccount;
